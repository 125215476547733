<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/factors.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Factors</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'create-enrollment', {})" :disabled="!$store.state.factors?.items.length">Create</b-button>
            </b-card-header>
            <b-card-body v-if="$store.state.enrollments">
                <b-table id="table" outlined hover :items="$store.state.enrollments.items" :fields="fields" primary-key="id" sort-by="label" :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/factors/${item.id}`)" class="mb-0" show-empty empty-text="You have no factors." empty-filtered-text="No factors match your search. If more factors are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button v-if="['ENABLED'].includes(data.item.status)" variant="outline-danger" size="sm" v-on:click="$emit('disable', 'enrollment', data.item.id)">Disable</b-button>
                        <b-button v-else variant="outline-success" size="sm" :disabled="['LOCKED'].includes(data.item.status)" v-on:click="['PENDING'].includes(data.item.status) ? $emit('validate', data.item) : $emit('enable', 'enrollment', data.item.id)">Enable</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'enrollments')">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.enrollments" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? $store.state.enrollments.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.enrollments?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'enrollments', $store.state.enrollments.nextToken)" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.enrollments?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.enrollments.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'score',
        label: 'Score',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'secret:id': 'Username',
    'secret:password': 'Password',
    'otp': 'One-Time Password',
    'totp': 'Authenticator App',
    'jwt:bearer': 'Personal Token',
    'jwt:spki': 'Private Key',
    'jwt:jwks': 'Hosted Key Set',
    'oauth2:quasr': 'Quasr',
    'oauth2:apple': 'Apple',
    'oauth2:slack': 'Slack',
    'oauth2:github': 'GitHub',
    'oauth2:google': 'Google',
    'oauth2:discord': 'Discord',
    'oauth2:linkedin': 'LinkedIn',
    'oauth2:facebook': 'Facebook',
    'oauth2:microsoft': 'Microsoft',
    'oauth2:oidc': 'OpenID Connect'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Enrollments',

    /**
     * EVENTS
     */
     emits: [ 'alert', 'login', 'load', 'next', 'show', 'disable', 'enable', 'validate' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.enrollments) {
            this.$emit('load', 'enrollments');
        }
        if (!this.$store.state.factors) {
            this.$emit('load', 'factors', true); // LOAD ALL
        } else if (this.$store.state.factors.nextToken) {
            this.$emit('next', 'factors', true); // LOAD ALL
        }
    }
}
</script>