<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Controls</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
            </b-card-header>
            <b-card-body v-if="$store.state.consents && $store.state.permissions">
                <b-table id="table" outlined hover :items="$store.state.consents.items.concat($store.state.permissions.items)" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/${item.type}s/${item.id}`)" class="mb-0" show-empty empty-text="You have no controls." empty-filtered-text="No controls match your search. If more controls are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button v-if="['PENDING','ENABLED'].includes(data.item.status)" variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status) || data.item.type === 'permission'" v-on:click="$emit('disable', data.item.type, data.item.id)">Disable</b-button>
                        <b-button v-else variant="outline-success" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status) || data.item.type === 'permission'" v-on:click="$emit('enable', data.item.type, data.item.id)">Enable</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listControls()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.consents && $store.state.permissions" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? ($store.state.consents.items.length + $store.state.permissions.items.length)) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.consents?.nextToken || $store.state.permissions?.nextToken" variant="outline-primary" v-on:click="nextControls()" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.consents?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.consents.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const TYPES = {
    'consent': 'Consent',
    'permission': 'Permission'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Controls',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'show', 'disable', 'enable', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.consents) {
            this.$emit('load', 'consents');
        }
        if (!this.$store.state.permissions) {
            this.$emit('load', 'permissions');
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * CONTROLS
         */
        async listControls() {
            this.$emit('load', 'consents');
            this.$emit('load', 'permissions');
        },

        async nextControls() {
            if (this.$store.state.consents.nextToken) {
                this.$emit('next', 'consents', this.$store.state.consents.nextToken);
            }
            if (this.$store.state.permissions.nextToken) {
                this.$emit('next', 'permissions', this.$store.state.permissions.nextToken);
            }
        }
    }
}
</script>