<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/subscriptions.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Subscriptions</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button v-if="!$store.state.payment?.id" class="ml-2" variant="outline-danger" to="/payment">Missing Payment</b-button>
                <b-button v-else class="ml-2" variant="primary" v-on:click="$emit('show', 'create-subscription', { plan: 'grav' })">Create</b-button>
            </b-card-header>
            <b-card-body v-if="$store.state.subscriptions">
                <b-table id="table" outlined hover :items="$store.state.subscriptions.items" :fields="fields" primary-key="id" sort-by="created" sort-desc :filter="search" class="mb-0" show-empty empty-text="You have no subscriptions." empty-filtered-text="No subscriptions match your search.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button variant="outline-danger" size="sm" :disabled="['inactive'].includes(data.item.status) || data.item.protected || data.item.cancel_at_period_end" v-on:click="$emit('show', 'cancel-subscription', data.item)">Cancel</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'subscriptions')">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.subscriptions?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.subscriptions.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'id',
        label: 'ID',
        sortable: false
    },
    {
        key: 'plan',
        label: 'Plan',
        sortable: true,
        formatter: value => PLANS[value]
    },
    {
        key: 'mat',
        label: 'MAT',
        sortable: true
    },
    {
        key: 'maa',
        label: 'MAA',
        sortable: true
    },
    {
        key: 'mac',
        label: 'MAC',
        sortable: true
    },
    {
        key: 'created',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value * 1000).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const PLANS = {
    'payg': 'Pay As You Go',
    'grav': 'Gravitate & Save',
    'strt': 'Startup',
    'ent': 'Enterprise'
};
const STATUSES = {
    'active': 'Active',
    'inactive': 'Inactive'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Subscriptions',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.subscriptions || !this.$store.state.subscriptions.items.length) {
            this.$emit('load', 'subscriptions');
        }
        if (!this.$store.state.payment) {
            this.$emit('load', 'payment');
        }
    }
}
</script>